import Logo from '../../assets/logos/logo_black.png'

const Header = () => {
	return (
		<div className="flex justify-center md:justify-start lg:justify-start w-full  h-max bg-black pb-4 mt-4">
			<img src={Logo} alt='logo' className="pt-2 pb-2 pl-4 pr-4 h-[125px]" />
		</div>
	)
}

export default Header