import { useState } from 'react';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import PricingTable from './components/PricingTable';

function App() {

	const [duration, setDuration] = useState("weekly")

  const handleToggleDuration = (duration) => {
		setDuration(duration)
	}

  return (
    <div className="w-full h-fit bg-black pl-[20px] md:p-[120px] lg:p-[120px] pr-[20px] pt-[20px] md:pt-[20px] lg:pt-[20px] pb-[20px]">
      <Header />
      <div className="pb-20 bg-white h-fit w-full">
        <div className="pt-[20px] pb-[20px] bg-[#6EE058]">
          <h1 className="ml-auto mr-auto w-fit text-black font-bold text-[35px] md:text-[45px] lg:text-[45px]">Become a Member</h1>
        </div>
        <h2 className="w-fit ml-auto mr-auto pt-8 font-semibold text-[18.5px] md:text-[28px] lg:text-[28px]">Select one of our memberships below</h2>
        <div className="mt-8 w-full flex justify-center gap-2">
          <span className="mt-auto text-[1.2rem] text-black font-semibold uppercase cursor-pointer select-none rounded-md p-1 pl-4 pr-4 border-2 border-[#6EE058]" style={{ opacity: duration === 'weekly' ? 1 : 0.5  }} onClick={() => handleToggleDuration('weekly')}>Weekly</span>
					<span className="mt-auto text-[1.2rem] text-black font-semibold uppercase cursor-pointer select-none rounded-md p-1 pl-4 pr-4 border-2 border-[#6EE058]" style={{ opacity: duration === 'monthly' ? 1 : 0.5  }} onClick={() =>  handleToggleDuration('monthly')}>Monthly</span>
        </div>
        <div className="pl-2 pr-2 mt-8 flex lg:flex-row w-full items-start justify-center gap-2">
          <PricingTable
            title="VIP Member"
            description={["Game Analysis", "Exclusive Ratings", "Predictions", "Injury Updates", "Live Odds/Spreads", "Betting Data", "Unlimited Website & App Access", "Plus receive instant notification of plays and key injury updates" ]}
            prices={{ weekly: 99, monthly: 299 }}
            checkoutLinks={{
              weekly: 'https://massaedge.com/index.php/register/vip-member-weekly/',
              monthly: 'https://massaedge.com/index.php/register/vip-member-monthly/'
            }}
            duration={duration}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
