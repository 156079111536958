const PricingTable = ({ title, description, prices, checkoutLinks, duration }) => {

	const handleRedirectCheckout = () => {
		window.location.href = checkoutLinks[duration]
	}

	return (
		<div className="flex flex-col items-center rounded-3xl bg-black text-white text-center w-[90%] md:w-[82%] lg:w-[42%] h-[520px] md:h-[660px] overflow-hidden">
			<span className="flex justify-center  items-center pt-2 pb-2 w-full text-[1.2rem] md:text-[2rem] lg:text-[2rem] font-bold uppercase bg-[#6EE058] text-black min-h-[115px]"><p className="max-w-[70%]">{title}</p></span>
			<span className="flex mt-2 text-[2.95rem] md:text-[4rem] lg:text-[5rem] font-semibold">${prices[duration]} <p className="ml-[2px] mt-auto mb-[30px] text-[12px] md:text-[16px] lg:text-[16px]">/{duration.replace('ly', '')}</p></span>
			<ul className="self-center mt-4 mb-8 text-left text-[0.84rem] md:text-[1.2rem] lg:text-[1.2rem] text-white max-w-[55%] list-disc">
				{description.map((item) => (
					<li>{item}</li>
				))}
			</ul>
			<div className="mt-auto flex flex-col">
				<button className="flex ml-auto mr-auto mb-4 p-2 pl-6 pr-6 text-[1.2rem] font-semibold bg-[#6EE058] text-black w-fit rounded-lg" onClick={handleRedirectCheckout}>SUBSCRIBE</button>
			</div>
		</div>
	)
}

export default PricingTable